import "./Team.scss";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const Team = () => {
  const [allteam, setAllteam] = useState(true);
  return (
    <div className="team">
      <div className="team-title">
        <p>TEAM</p>
      </div>

      <div className="people">
        <div>
          <a
            href="https://www.instagram.com/tramtramdia"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic anna"></div>
              <h5>
                Creative director, <span>photographer</span>
              </h5>
              <h3>Anna Sydorenko</h3>
            </div>
          </a>
        </div>
        <div>
          <a
            href="https://www.facebook.com/profile.php?id=100000505625428"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic sasha"></div>
              <h5>Strategist</h5>
              <h3>Sasha Kordiiaka</h3>
            </div>
          </a>
        </div>

        <div>
          <a
            href="https://www.facebook.com/kate.sema.5"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic kate"></div>
              <h5>Art director</h5>
              <h3>Kate Sema</h3>
            </div>
          </a>
        </div>

        <div>
          <a href="http://lanared.pro" target="_blank" rel="noreferrer">
            <div className="person-wrapper">
              <div className="person-pic sveta"></div>
              <h5>
                Make up artist, <span>hair stylist</span>
              </h5>
              <h3>Svitlana Mamoshyna</h3>
            </div>
          </a>
        </div>

        <div>
          <a
            href="https://www.facebook.com/profile.php?id=100007749586465"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic vira"></div>
              <h5>
                Floral design, <span>UX/UI design</span>
              </h5>
              <h3>Vira Trambovetska</h3>
            </div>
          </a>
        </div>

        <div>
          <a
            href="https://www.facebook.com/helen.solovey"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic helen"></div>
              <h5>Floral design</h5>
              <h3>Helen Solovey</h3>
            </div>
          </a>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <a
            href="https://www.facebook.com/lyudmila.yaschuk"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic mila"></div>
              <h5>Floral design</h5>
              <h3>Mila Umakhanova</h3>
            </div>
          </a>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <div className="person-wrapper">
            <div className="person-pic oksana"></div>
            <h5>Floral design</h5>
            <h3>Oksana Sokolova</h3>
          </div>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <a
            href="https://ofishman.wixsite.com/alenasartstudio2"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic fishman"></div>
              <h5>Floral design</h5>
              <h3>Aliona Fishman</h3>
            </div>
          </a>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <a
            href="https://www.instagram.com/vintage_usa_jewelry/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic ira"></div>
              <h5>Accessories</h5>
              <h3>Iryna Rybytva</h3>
            </div>
          </a>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <a
            href="https://www.facebook.com/mariia.surdu"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic masha"></div>
              <h5>Accessories</h5>
              <h3>Mariia Surdu</h3>
            </div>
          </a>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <a
            href="https://www.facebook.com/anna.laird.96"
            target="_blank"
            rel="noreferrer"
          >
            <div className="person-wrapper">
              <div className="person-pic laird"></div>
              <h5>Accessories</h5>
              <h3>Anna Laird</h3>
            </div>
          </a>
        </div>

        <div className={allteam ? "hide" : "show"}>
          <div className="person-wrapper">
            <div className="person-pic lisyk"></div>
            <h5>
              Developer, <span>video production</span>
            </h5>
            <h3>Lisyk</h3>
          </div>
        </div>
      </div>

      <div
        className={allteam ? "expand-hide" : "expand-show"}
        onClick={() => setAllteam(!allteam)}
      >
        <p className={allteam ? "team-show-icon" : "team-hide-icon"}>
          Show all team members
        </p>
        <p className={allteam ? "team-hide-icon" : "team-show-icon"}>
          Hide team members
        </p>
        <ExpandMoreIcon
          className={allteam ? "team-show-icon" : "team-hide-icon"}
        />
        <ExpandLessIcon
          className={allteam ? "team-hide-icon" : "team-show-icon"}
        />
      </div>
    </div>
  );
};

export default Team;
