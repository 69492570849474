import "./About.scss";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import React from "react";

const About = () => {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="about">
      <h2>ABOUT</h2>
      <div className={expanded ? "about-show" : "about-hide"}>
        A mirror can reveal who you truly are. It can show you as strong yet
        vulnerable, virtuous yet imperfect, but honest and brave no matter what.
        The US-UA Mirror project is connecting the past and the future, uniting
        all women in their strength and indomitable spirit.
        <br /> For over a year, we've been volunteering in various ways to
        generate meaningful and so-needed support and assistance for our home
        country. To be honest, by now, we're exhausted beyond belief, but we
        have no moral right or choice to give up. So, we are looking for more
        ways to help. <br />
        Amazed by the charitable Ukrainian-style photo sessions in London and
        Paris we decided to bring this initiative to the DMV area. The outfits
        and accessories are all made by skilled Ukrainian artisans, and the
        techniques used have been passed down through generations. The highlight
        of every look will be the floral head wreaths favored by Ukrainian
        women. They change the way you look and they change the way you feel.
        <br />
        With this initiative, we would like to thank everyone who volunteers to
        support Ukraine for the incredible work you have done and continue to do
        in the midst of the humanitarian disaster.
        <span>
          Please consider joining us in our efforts to further help Ukrainians.
        </span>
      </div>
      <div className="expand-block">
        <div
          className={expanded ? "hide" : "show"}
          onClick={() => setExpanded(true)}
        >
          <p>Show all text</p>
          <ExpandMoreIcon />
        </div>
        <div
          className={expanded ? "show" : "hide"}
          onClick={() => setExpanded(false)}
        >
          <p>Show less information</p>
          <ExpandLessIcon />
        </div>
      </div>
    </div>
  );
};

export default About;
