import "./Home.scss";

import Intro from "../../components/Intro/Intro";
import Topbar from "../../components/Topbar/Topbar";
import Details from "../../components/Details/Details";
import Payment from "../../components/Payment/Payment";
import Conditions from "../../components/Conditions/Conditions";
import About from "../../components/About/About";
import MainPartner from "../../components/MainPartner/MainPartner";
import Fundraisers from "../../components/Fundraisers/Fundraisers";
import Team from "../../components/Team/Team";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  return (
    <div className="home">
      <div className="app-wrapper">
        <Topbar />
        <Intro />
        <Details />
        <Payment />
        <Conditions />
        <About />
        <MainPartner />
        <Fundraisers />
        <Team />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
