import Home from "./pages/Home/Home";
import Faq from "./pages/Faq/Faq";
import "./App.scss";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <div className="app">
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },

      {
        path: "/support",
        element: <Faq />,
      },
      {
        path: "/help-center",
        element: <Faq />,
      },

      {
        path: "/terms",
        element: <Faq />,
      },
      {
        path: "/privacy",
        element: <Faq />,
      },
      {
        path: "/cancellation",
        element: <Faq />,
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
