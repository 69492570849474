import Foot from "../../components/Foot/Foot";
import Header from "../../components/Header/Header";
import Support from "../../components/Support/Support";
import Privacy from "../../components/Privacy/Privacy";
import Refund from "../../components/Refund/Refund";
import Terms from "../../components/Terms/Terms";
import "./Faq.scss";

const Faq = () => {
  return (
    <div className="faq-wrapper" id="support-hash">
      <Header />
      <div className="faqs">
        <h2>Help center</h2>
      </div>
      <Support />
      <Privacy />
      <Terms />
      <Refund />
      <Foot />
    </div>
  );
};

export default Faq;
