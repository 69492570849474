import "./Header.scss";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Header = () => {
  return (
    <div className="header">
      <div className="wrapper">
        <div className="left">
          <h2>
            <HashLink to="/#about">About</HashLink>
          </h2>
          <h2>
            <HashLink to="/#fundraisers">Fundraisers</HashLink>
          </h2>
          <h2>
            <HashLink to="/#team">Team</HashLink>
          </h2>
        </div>
        <Link to="/">
          <div className="logo">
            <img src={require("../../assets/img/logo-w.webp")} alt="logo" />
          </div>
        </Link>
        <a href="/#donate" className="right">
          <span>Donate</span>
        </a>
      </div>
    </div>
  );
};

export default Header;
