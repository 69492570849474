import "./Terms.scss";
import { useState } from "react";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Terms = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="terms">
      <h2 className="terms-h">Terms and conditions</h2>
      <div className="terms-text">
        <div className="visible">
          Thank you for your interest in participating in our photo session
          event to support Ukraine. Before you proceed, please read these Terms
          and Conditions carefully. Donation Requirement: To be eligible to have
          a chance to participate in the photo session, you must donate a
          minimum of $50 that will go directly to one of our charity supporting
          Ukraine.
        </div>
        <div className={expanded ? "visible" : "collapsed"}>
          We will not be able to provide a photo session to anyone who has not
          met this requirement. Only five people from all the donators will be
          selected randomly. Eligibility: Participants must be at least 18 years
          old and have a valid government-issued ID to participate. By
          participating in the photo session, you represent and warrant that you
          meet these eligibility requirements. Process: We will contact you via
          email with everyone who donate $50 or more with additional
          information. We cannot guarantee a photosession foir everyone, only 5
          people will be selected. Release and License: By participating in the
          photo session, you agree to grant us the right to use your image and
          likeness in any and all media for any purpose whatsoever, including
          but not limited to promotional materials and social media. You also
          agree to release us from any and all claims arising out of or in
          connection with the use of your image and likeness. Liability: You
          agree that we are not liable for any injury, loss, or damage to
          persons or property that may occur during the photo session. You also
          agree to indemnify and hold us harmless from any and all claims
          arising out of or in connection with your participation in the photo
          session. Cancellation Policy: If you need to cancel your photo
          session, please let us know at least 10 days in advance. We reserve
          the right to retain a portion of your donation to cover any expenses
          incurred in connection with the photo session. Modifications: We
          reserve the right to modify these Terms and Conditions at any time, in
          our sole discretion. Any modifications will be effective immediately
          upon posting on our website. By participating in the photo session,
          you acknowledge that you have read and agree to these Terms and
          Conditions. If you do not agree to these Terms and Conditions, you may
          not participate in the photo session and event.
        </div>
      </div>
      <div className="terms-end" onClick={() => handleExpand()}>
        <ArrowDownwardSharpIcon
          className={expanded ? "arrow-point-down" : "arrow-point-up"}
        />
      </div>
    </div>
  );
};

export default Terms;
