import "./Refund.scss";
import { useState } from "react";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Refund = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="refund" id="refund-hash">
      <h2 className="refund-h">Refund and Cancellation Policy</h2>
      <div className="refund-text">
        <div className="visible">
          Thank you for supporting Ukraine through our photo session donation
          program. Your contribution makes a difference in the lives of many
          individuals, and we are grateful for your support. <br />
        </div>
        <br />
        Please note that we do not offer refunds or cancellations for our photo
        session donations.
        <br />
        <br />
        If you are unable to attend your scheduled photo session, please notify
        us at least 24 hours in advance, and we will do our best to reschedule
        your session. However, please note that rescheduling is subject to the
        availability of our photographer, and we cannot guarantee a new session
        time.
        <br />
        <br />
        <div className={expanded ? "visible" : "collapsed"}>
          If you have any questions or concerns about our refund and
          cancellation policy, please do not hesitate to contact us. We
          appreciate your support and look forward to capturing beautiful
          memories with you.
        </div>
      </div>
      <div className="refund-end" onClick={() => handleExpand()}>
        <ArrowDownwardSharpIcon
          className={expanded ? "arrow-point-down" : "arrow-point-up"}
        />
      </div>
    </div>
  );
};

export default Refund;
