import "./Details.scss";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Details = () => {
  return (
    <div className="details">
      <div className="details-grid">
        <div className="grid-item item1"></div>
        <div className="grid-item item2">
          <p>
            We are a team of Ukrainian women who are raising funds to support
            Ukraine. To show our appreciation, we want to give back to our
            donors by randomly selecting five individuals among those who donate
            $50 or more and inviting them for a stunning photoshoot in a
            Ukrainian outfit. Additionally, as a token of our deepest gratitude,
            every sponsor who contributes $500 or more will be guaranteed a
            photoshoot
          </p>
        </div>
        <div className="grid-item item3"></div>
        <div className="grid-item item4"></div>
        <span id="donate"></span>
        <div className="arrowDown">
          <ArrowDownwardSharpIcon sx={{ fontSize: 60 }} className="arrow" />
        </div>
      </div>
    </div>
  );
};

export default Details;
