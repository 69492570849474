import "./Payment.scss";

const Payment = () => {
  return (
    <div className="payment">
      <div className="donate">
        <div className="subject">
          <span className="sum">$50</span>
          <span className="tickets"></span>
        </div>

        <div className="donate-details">
          A donor is given one opportunity to be chosen for a photoshoot
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/3csaIL7U8btEgg0bIJ"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Donate</div>
          </a>
        </div>
      </div>
      <div className="donate">
        <div className="subject">
          <span className="sum">$100</span>
          <span className="tickets"></span>
        </div>

        <div className="donate-details">
          A donor is given two opportunities to be chosen for a photoshoot
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/eVa6sv7U80P03tedQS"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton donate-red">Donate</div>
          </a>
        </div>
      </div>
      <div className="donate">
        <div className="subject">
          <span className="sum">$150</span>
          <span className="tickets"></span>
        </div>

        <div className="donate-details">
          A donor is given three opportunities to be chosen for a photoshoot
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/3cs2cf3DScxI3te8wz"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Donate</div>
          </a>
        </div>
      </div>
      <div className="donate">
        <div className="subject">
          <span className="sum">$500</span>
          <span className="tickets"></span>
        </div>

        <div className="donate-details">
          Donate $500 or more and you will lock your spot
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/4gw3gj0rGapA4xifZ2"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Donate</div>
          </a>
        </div>
      </div>
      <div className="donate-any">
        <div className="subject">
          <span className="sum"></span>
          <span className="tickets"></span>
        </div>
        <div className="donate-details">
          You can donate any amount at your discretion to support our project
          without participating in the event
        </div>
        <div className="button-block">
          <a
            href="https://donate.stripe.com/aEUcQT2zO2X8fbWbIN"
            target="_blank"
            rel="noreferrer"
          >
            <div className="donateButton">Donate</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Payment;
