import "./Footer.scss";

import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  return (
    <div className="footer">
      <div className="logo">
        <img src={require("../../assets/img/logo-w.webp")} alt="logo" />
      </div>
      <div className="menu">
        <h3>
          <a href="#about">About</a>
        </h3>
        <h3>
          <a href="#fundraisers">Fundraisers</a>
        </h3>
        <h3>
          <a href="#team">Team</a>
        </h3>
        <h3 className="donate-button">
          <a href="#donate">Donate</a>
        </h3>
        <h3>
          <HashLink to="/help-center#support-hash">Help center</HashLink>
        </h3>
      </div>
      <div className="social-mobile">
        <a href="https://www.facebook.com/usuamirror">
          <FacebookIcon className="facebook" />
        </a>
        <a href="https://www.instagram.com/usuamirror/">
          <InstagramIcon className="insta" />
        </a>
      </div>
      <div className="term">
        <h3>
          <HashLink to="/support#support-hash">Support</HashLink>
        </h3>
        <h3>
          <HashLink to="/privacy#privacy-hash">Privacy policy</HashLink>
        </h3>
        <h3>
          <HashLink to="/terms#terms-hash">Terms and conditions</HashLink>
        </h3>
        <h3>
          <HashLink to="/cancellation#cancellation-hash">
            Refund and Cancellation policy
          </HashLink>
        </h3>
      </div>
      <div className="contacts">
        <div className="address">
          <p>Mirror Project, USA</p>
          <p>Ashburn, VA 20148</p>
          <p>(571) 435-32-15</p>
        </div>
        <div className="mailing">
          <p>For inquiries or support please contact</p>
          <p>
            us at <span>mirror@freedomua.org</span>
          </p>
        </div>
      </div>
      <div className="footer-low">
        <div className="copyrights">© 2023 by Lisyk & Vira Trambovetska.</div>
        <div className="credit-cards"></div>
        <div className="social">
          <a href="https://www.facebook.com/usuamirror">
            <FacebookIcon className="facebook" />
          </a>
          <a href="https://www.instagram.com/usuamirror/">
            <InstagramIcon className="insta" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
