import "./Foot.scss";
import { HashLink } from "react-router-hash-link";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";

const Foot = () => {
  return (
    <div className="foot">
      <div className="logo">
        <HashLink to="/">
          <img src={require("../../assets/img/logo-w.webp")} alt="logo" />
        </HashLink>
      </div>
      <div className="menu">
        <h3>
          <HashLink to="/#about">About</HashLink>
        </h3>
        <h3>
          <HashLink to="/#fundraisers">Fundraisers</HashLink>
        </h3>
        <h3>
          <HashLink to="/#team">Team</HashLink>
        </h3>
        <h3 className="donate-button">
          <a href="#donate">Donate</a>
        </h3>
      </div>
      <div className="social-mobile">
        <a href="https://www.facebook.com/usuamirror">
          <FacebookIcon className="facebook" />
        </a>
        <a href="https://www.instagram.com/usuamirror/">
          <InstagramIcon className="insta" />
        </a>
      </div>
      <div className="term">
        <h3>
          <HashLink to="#support-hash">Support</HashLink>
        </h3>
        <h3>
          <HashLink to="#privacy-hash">Privacy policy</HashLink>
        </h3>
        <h3>
          <HashLink to="#terms-hash">Terms and conditions</HashLink>
        </h3>
        <h3>
          <HashLink to="#refund-hash">Refund and Cancellation policy</HashLink>
        </h3>
      </div>
      <div className="contacts">
        <div className="address">
          <p>Mirror Project, USA</p>
          <p>Ashburn, VA 20148</p>
          <p>(571) 435-32-15</p>
        </div>
        <div className="mailing">
          <p>For inquiries or support please contact</p>
          <p>
            us at <span>mirror@freedomua.org</span>
          </p>
        </div>
      </div>
      <div className="footer-low">
        <div className="copyrights">© 2023 by Lisyk & Vira Trambovetska.</div>
        <div className="credit-cards"></div>
        <div className="social">
          <a href="https://www.facebook.com/usuamirror">
            <FacebookIcon className="facebook" />
          </a>
          <a href="https://www.instagram.com/usuamirror/">
            <InstagramIcon className="insta" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Foot;
