import "./Privacy.scss";
import { useState } from "react";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Privacy = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <div className="privacy">
      <h2 className="privacy-h">Privacy policy</h2>
      <div className="privacy-text">
        <div className="visible">
          <p>
            Thank you for considering participating in a photo session to
            support Ukraine. At our website, we value your privacy and
            understand the importance of keeping your personal information safe.
            This Privacy Policy describes how we collect, use, and disclose your
            personal information in connection with your use of our website.
          </p>
        </div>
        <div className={expanded ? "visible" : "collapsed"}>
          <h3>Information We Collect</h3>
          <p>
            We collect personal information from you when you choose to
            participate in a photo session by donating a certain amount of money
            to support Ukraine. The personal information we collect may include
            your name, email address, and payment information.{" "}
          </p>
          <h3>How We Use Your Information</h3>
          <p>
            We use your personal information to process your donation and to
            communicate with you about the photo session, including scheduling
            and any updates related to the session. We don't use your personal
            information to send you any other marketing communications non
            related to our photo sessions event.
          </p>
          <h3>Sharing Your Information</h3>
          <p>
            We do not sell, rent, or lease your personal information to third
            parties. However, we may share your personal information with
            third-party service providers who assist us in processing your
            donation or providing other services related to our website.
          </p>
          <h3>Security of Your Information</h3>
          <p>
            We take reasonable measures to protect your personal information
            from unauthorized access, disclosure, alteration, or destruction.
            However, no method of transmission over the internet or electronic
            storage is 100% secure, and we cannot guarantee its absolute
            security.
          </p>
          <h3>Your Choices</h3>
          <p>
            You have the right to access and update your personal information,
            and to request that we delete it. You may also opt-out of receiving
            marketing communications from us at any time. We may update this
            Privacy Policy from time to time in response to changing legal,
            technical, or business developments.
          </p>
          <h3>Changes to this Privacy Policy</h3>
          <p>
            If we make material changes to this Privacy Policy, we will provide
            you with notice as required by law. If you have any questions or
            concerns about this Privacy Policy or our data practices, please
            contact us at: mirror@freedomua.org.
          </p>
        </div>
      </div>

      <div
        className="privacy-end"
        id="terms-hash"
        onClick={() => handleExpand()}
      >
        <ArrowDownwardSharpIcon
          className={expanded ? "arrow-point-down" : "arrow-point-up"}
        />
      </div>
    </div>
  );
};

export default Privacy;
