import "./Fundraisers.scss";
import { useState } from "react";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const Fundraisers = () => {
  const [uhu, setUhu] = useState(false);
  const [church, setChurch] = useState(false);
  const [ufcu, setUfcu] = useState(false);

  return (
    <div className="fundraisers">
      <h2>FUNDRAISERS</h2>
      <p className="text-fundraise">
        All funds raised during the campaign will be distributed between UHU,
        St.Andrew Ukrainian Orthodox Church, and United for the Children of
        Ukraine.
      </p>

      <div className="org">
        <div className="uhu-collapse" onClick={() => setUhu(!uhu)}>
          <p>01</p>
          <p className="org-name">United Help Ukraine</p>
          <ExpandMoreIcon className={uhu ? "uhu-hide" : "uhu-show"} />
          <ExpandLessIcon className={uhu ? "uhu-show" : "uhu-hide"} />
        </div>

        <div className={uhu ? "uhu-block-show" : "uhu-block-hide"}>
          <p className="number">01</p>
          <div className="logo uhu-logo"></div>
          <div className="all-text">
            <p>
              United Help Ukraine is a charitable non-profit 501(c)(3)
              organization. It was started in 2014, after the Russian attacked
              on Ukraine, by a small group of people who met at a protest in
              Washington, DC.
            </p>
            <a
              href="https://unitedhelpukraine.org/"
              target="_blank"
              rel="noreferrer"
            >
              Go to UHU site
              <ArrowDownwardSharpIcon
                sx={{
                  transform: "rotate(-90deg)",
                  fontSize: "18px",
                  marginLeft: "8px",
                  marginBottom: "-5px",
                }}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="org">
        <div className="church-collapse" onClick={() => setChurch(!church)}>
          <p>02</p>
          <p className="org-name">St.Andrew Ukrainian Orthodox Church</p>
          <ExpandMoreIcon className={church ? "church-hide" : "church-show"} />
          <ExpandLessIcon className={church ? "church-show" : "church-hide"} />
        </div>
        <div className={church ? "church-block-show" : "church-block-hide"}>
          <p className="number">02</p>
          <div className="logo st-logo"></div>
          <div className="all-text">
            <p>
              St.Andrew Ukrainian Orthodox Church - a church organization since
              1949 and a charitable non-profit 501(c)(3) organization. We are
              forever grateful for the outpouring of solidarity, prayers, and
              support.
            </p>
            <a href="https://standrewuoc.org/" target="_blank" rel="noreferrer">
              Go to St.Andrew Ukrainian Orthodox Church site
              <ArrowDownwardSharpIcon
                sx={{
                  transform: "rotate(-90deg)",
                  fontSize: "18px",
                  marginLeft: "8px",
                  marginBottom: "-5px",
                }}
              />
            </a>
          </div>
        </div>
      </div>

      <div className="org">
        <div className="ufcu-collapse" onClick={() => setUfcu(!ufcu)}>
          <p>03</p>
          <p className="org-name">United for the Children of Ukraine</p>
          <ExpandMoreIcon className={ufcu ? "ufcu-hide" : "ufcu-show"} />
          <ExpandLessIcon className={ufcu ? "ufcu-show" : "ufcu-hide"} />
        </div>

        <div className={ufcu ? "ufcu-block-show" : "ufcu-block-hide"}>
          <p className="number">03</p>
          <div className="logo ucu-logo"></div>
          <div className="all-text">
            <p>
              United for the Children of Ukraine is a charitable non-profit
              501(c)(3) organization, providing aid to Ukrainian orphans
              affected by the war.
            </p>
            <a
              href="https://www.facebook.com/profile.php?id=100087538044410"
              target="_blank"
              rel="noreferrer"
              id="team"
            >
              Go to UFCU site
              <ArrowDownwardSharpIcon
                sx={{
                  transform: "rotate(-90deg)",
                  fontSize: "18px",
                  marginLeft: "8px",
                  marginBottom: "-5px",
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fundraisers;
