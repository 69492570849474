import React from "react";
import "./Intro.scss";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Intro = () => {
  return (
    <div className="intro" id="intro">
      <div className="video-background">
        <video autoPlay playsInline muted loop preload="auto" id="cover-video">
          <source
            src={require("../../assets/video/shortv6.mp4")}
            type="video/mp4"
          />
          <p>
            Your browser doesn't support this video. Here is a
            <a href="https://youtu.be/hrHU_X5dBrI">link to the video</a>{" "}
            instead.
          </p>
        </video>
      </div>
      <div className="text">
        <div className="charity-block block-1">
          <span className="text-wrapper">US UA CHARITY EVENT</span>
          <ArrowDownwardSharpIcon className="arrow" />
        </div>
        <div className="charity-block block-2">
          <span className="text-wrapper">US UA CHARITY EVENT</span>
          <ArrowDownwardSharpIcon className="arrow" />
        </div>
        <div className="charity-block block-3">
          <span className="text-wrapper">US UA CHARITY EVENT</span>
          <ArrowDownwardSharpIcon className="arrow" />
        </div>
        <div className="charity-block block-4">
          <span className="text-wrapper">US UA CHARITY EVENT</span>
          <ArrowDownwardSharpIcon className="arrow" />
        </div>
        <div className="charity-block block-5">
          <span className="text-wrapper">US UA CHARITY EVENT</span>
        </div>
      </div>
    </div>
  );
};

export default Intro;
