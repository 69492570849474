import "./MainPartner.scss";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const MainPartner = () => {
  return (
    <div className="main-partner">
      <h2>MAIN PARTNER</h2>
      <div className="ua-house-logo"></div>
      <div className="all-text">
        <p className="ua-house-text">
          The event is held with support of UKRAINE HOUSE.
          <br />
          UKRAINE HOUSE is a home away from home for many Ukrainians by birth
          and Ukrainians by choice <br /> in Washington, DC.
        </p>
        <a href="https://www.ukrainehouse.us/" target="_blank" rel="noreferrer">
          Go to Ukraine House site
          <ArrowDownwardSharpIcon
            sx={{
              transform: "rotate(-90deg)",
              fontSize: "17px",
              marginLeft: "8px",
              marginBottom: "-5px",
            }}
          />
        </a>
      </div>
      <span id="fundraisers"></span>
    </div>
  );
};

export default MainPartner;
