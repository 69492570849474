import "./Support.scss";
import { useState } from "react";
import ArrowDownwardSharpIcon from "@mui/icons-material/ArrowDownwardSharp";

const Support = () => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="support">
      <h2 className="support-h">SUPPORT</h2>
      <div className="support-text">
        <p className="visible">
          Welcome to our support page for USUA Mirror event! We are thrilled
          that you have taken an interest in contributing to our cause. We
          believe that by coming together, we can make a positive impact on the
          lives of those affected by the war in Ukraine. To show our
          appreciation for your
        </p>
        <p className={expanded ? "visible" : "collapsed"}>
          donation, we are offering a unique opportunity to participate in a
          photo session. This is our way of saying thank you for your
          generosity, and we hope that it serves as a fun and memorable
          experience for you. Here's how it works: if you donate a certain
          amount of money to our cause, you will be eligible to participate in a
          professional photo session. Our talented photographers will work with
          you to create stunning images that you can cherish for a lifetime. Not
          only will you be supporting a worthy cause, but you will also have the
          chance to create beautiful memories that you can treasure forever.
          It's a win-win situation! We want to emphasize that your donation goes
          directly towards supporting Ukraine. Your contribution will help
          provide critical resources and aid to those affected by the ongoing
          war. We believe that every donation, no matter how big or small, can
          make a significant difference in someone's life. Thank you for your
          consideration, and we hope that you will join us in our efforts to
          support Ukraine. If you have any questions or concerns, please don't
          hesitate to reach out to us. We appreciate your support!
        </p>
      </div>
      <div
        className="support-end"
        id="privacy-hash"
        onClick={() => handleExpand()}
      >
        <ArrowDownwardSharpIcon
          className={expanded ? "arrow-point-down" : "arrow-point-up"}
        />
      </div>
    </div>
  );
};

export default Support;
